.ball {
  animation: animate-bounce 2s infinite alternate;
  -webkit-animation: animate-bounce 2s infinite alternate;
}
@keyframes animate-bounce {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-30px);
  }
}
@-webkit-keyframes animate-bounce {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-30px);
  }
}



#container {
  width: 580px;
  text-align: center;
  margin: auto;
}
#line1 {
  font-size: 0;
  width: 1px;
  height: 60px;
  color: #fff;
  margin: auto;

}


#line0 {
  font-size: 0;
  width: 180px;
  height: 1px;
  color: #fff;
  margin-left: 140px;
}

#line2 {
  font-size: 0;
  width: 146px;
  height: 1px;
  color: #fff;
  margin-left: 140px;
}
#line3 {
  font-size: 0;
  display: inline;
  width: 1px;
  height: 40px;
  color: #fff;
  margin-left: 140px;
  float: left;
  background-color: #ccc;
}
#line4 {
  margin-top: 82px;
  width: 50px;
  height: 1px;
  color: #fff;
  margin-left: 0px;
}
#line5 {
  font-size: 0;
  width: 1px;
  height: 60px;
  color: #fff;
  margin-left: 350px;
}
#line6 {
  width: 140px;
  height: 1px;
  color: #fff;
  margin-left: 210px;
}
#line7 {
  font-size: 0;
  display: inline;
  width: 1px;
  height: 40px;
  color: #fff;
  margin-left: 210px;
  float: left;
}
#line50 {
  font-size: 0;
  width: 1px;
  height: 60px;
  color: #fff;
  margin-left: 350px;
}
#line60 {
  width: 180px;
  height: 1px;
  color: #fff;
  margin-left: 170px;
}
#line70 {
  font-size: 0;
  display: inline;
  width: 1px;
  height: 40px;
  color: #fff;
  margin-left: 170px;
  float: left;
}
#line8 {
  font-size: 0;
  width: 1px;
  height: 40px;
  color: #fff;
  background-color: #000;
  margin: auto;
}
#line9 {
  width: 724px;
  height: 1px;
  color: #fff;
  background-color: #000;
  /* margin: auto; */
}
#line10 {
  font-size: 0;
  display: inline;
  width: 1px;
  height: 40px;
  color: #fff;
  background-color: #000;
  margin-left: 0px;
  float: left;
}
#line11 {
  font-size: 0;
  display: inline;
  width: 1px;
  height: 40px;
  color: #fff;
  background-color: #000;
  margin-left: 360px;
  float: left;
}
#line12 {
  font-size: 0;
  display: inline;
  width: 1px;
  height: 40px;
  color: #fff;
  background-color: #000;
  margin-left:100px;
  float: right;
}
#line13 {
  margin-top: 328px;
  font-size: 0;
  display: inline;
  width: 1px;
  height: 38px;
  color: #fff;
  background-color: #000;
  margin-left: -140px;
  float: left;
}
#line14 {
  margin-top: 328px;
  font-size: 0;
  display: inline;
  width: 1px;
  height: 38px;
  color: #fff;
  background-color: #000;
  margin-left: -510px;
  float: left;
}

#line15 {
  font-size: 0;
  display: inline;
  width: 1px;
  height: 88px;
  color: #fff;
  background-color: #000;
  margin-right: 380px;
  float: right;
}
#line16 {
  font-size: 0;
  display: inline;
  width: 1px;
  height: 88px;
  color: #fff;
  background-color: #000;
  margin-right: 190px;
  float: right;
}
#line17 {
  font-size: 0;
  display: inline;
  width: 1px;
  height: 88px;
  color: #fff;
  background-color: #000;
  margin-right: 190px;
  float: right;
}
#no1 {
  /* margin-top: 8px; */
  display: inline;
  clear: both;
  margin-left: -150px;
  float: left;
}

#no2 {
  margin-top: 8px;
  display: inline;
  margin-left: 80px;
  float: left;
}
#no3 {
  margin-top: 8px;
  display: inline;
  /* margin-left: 10px; */
  float: left;
}
#no4 {
  margin-top: 8px;
  display: inline;
  margin-right: 810px;
  float: right;
}
#no5 {
  margin-top: 8px;
  display: inline;
  margin-left: 450px;
  float: left;
}

.circle1 {
  margin-top: 46px;
  margin-left: -6px;
  display: inline-block;
  border-radius: 50%;
  width: 16px;
  height: 16px;
 
}
.circle2 {
  margin-top: 74px;
  margin-right: -8px;
  display: inline-block;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background: #cec382;
}
.circle3 {
  margin-top: 32px;
  margin-left: -8px;
  display: inline-block;
  border-radius: 50%;
  width: 16px;
  height: 16px;
}
.circle4 {
  margin-top: 40px;
  margin-left: -8px;
  display: inline-block;
  border-radius: 50%;
  width: 16px;
  height: 16px;
}
.circle5 {
  margin-top: 88px;
  margin-left: -8px;
  display: inline-block;
  border-radius: 50%;
  width: 16px;
  height: 16px;
}
